<script setup lang="ts">
import { promiseTimeout } from '@vueuse/core'
import { differenceInDays } from 'date-fns'
import { WsEventType } from '~/codegen/graphql'
import { GetMentionsDocument, GetToDosDocument, MarkMentionAsSeenDocument } from './Popup.generated'

const { watchAndExecute } = useWebsocket()

const todoUpdateEvents = [
  WsEventType.ReviewUpdated,
  WsEventType.ReviewPublished,
  WsEventType.ReviewInProgress,
  WsEventType.ReviewStopped,
  WsEventType.ReviewRemoved,
  WsEventType.SectionStatusUpdated,
  WsEventType.SectionUpdated,
]
const mentionsUpdateEvents = [
  WsEventType.CommentAdded,
  WsEventType.SectionAssessmentUpdated,
  WsEventType.ReviewAssessmentUpdated,
  WsEventType.WidgetValueUpdated,
]

const activeTab = ref('todo')
const tabs = [
  {
    key: 'todo',
    label: 'To do',
  },
  {
    key: 'mentions',
    label: 'Mentions',
  },
]

const paused = ref(true)
onMounted(async () => {
// Basically just delay the fetch until everything else is done
  await waitForElement('[data-element="spinner"]', true)
  await promiseTimeout(1000)
  paused.value = false
})

const { data: todos, isDone: isDoneTodos, execute: refetchTodos } = useQuery({
  query: GetToDosDocument,
  cachePolicy: 'network-only',
  paused,
}, ({ data }) => data?.chores)

const { data: mentions, isDone: isDoneMentions, execute: refetchMentions } = useQuery({
  query: GetMentionsDocument,
  cachePolicy: 'network-only',
  paused,
  tags: ['inbox-mentions'],
}, ({ data }) => data?.mentions)

const { execute: markMentionAsRead } = useMutation(MarkMentionAsSeenDocument, {
  refetchTags: ['inbox-mentions'],
})
watchAndExecute({ events: todoUpdateEvents, matcher: () => true, cb: refetchTodos, skipOwn: false })
watchAndExecute({ events: mentionsUpdateEvents, matcher: data => !data.contextId, cb: refetchMentions, skipOwn: false })

const dueIn24Hours = computed(() => {
  return todos.value?.nodes.some(todo => differenceInDays(new Date(todo.dueOn), new Date()) < 1)
})

const unreadMentions = computed(() => {
  return mentions.value?.nodes.some(mention => !mention.seenOn)
})

const showBadge = computed(() => {
  if (!isDoneTodos.value || !isDoneMentions.value)
    return false
  return todos.value?.totalCount || unreadMentions.value
})

function markAllAsRead() {
  mentions.value?.nodes.filter(mention => !mention.seenOn).forEach((mention) => {
    markMentionAsRead({ id: mention.id })
  })
}
</script>

<template>
  <UPopover
    :popper="{ placement: 'right-end' }"
    class="text-base"
  >
    <UChip
      size="xs"
      :ui="{
        position: { 'top-right': 'top-2 right-2' },
        base: `transition-opacity ${dueIn24Hours ? 'animate-pulse' : 'animate-none'} ${showBadge ? 'opacity-1' : 'opacity-0'}`,
      }"
    >
      <UButton
        icon="solid:inbox"
        variant="ghost"
        color="white-nav"
        size="xs"
        class="!text-gray-500 ring-0 transition-[shadow,transform]"
        data-action="inbox-open"
        :ui="{ base: '!p-2', icon: { size: { xs: 'size-[0.875rem]' } } }"
      />
    </UChip>

    <template #panel>
      <div class="flex h-[90vh] w-[560px] flex-col">
        <h2 class="p-4">
          Inbox
        </h2>
        <WTabs v-model="activeTab" :tabs="tabs" no-padding scroll>
          <template #todo-badge>
            <div class="ml-1 rounded-full bg-blue-50 px-2 text-sm text-gray-950 dark:bg-blue-900 dark:text-gray-50">
              {{ todos?.totalCount }}
            </div>
          </template>
          <template #mentions-badge>
            <div class="ml-1 rounded-full bg-blue-50 px-2 text-sm text-gray-950 dark:bg-blue-900 dark:text-gray-50">
              {{ mentions?.totalCount }}
            </div>
          </template>

          <template #todo>
            <InboxZero v-if="todos?.totalCount === 0" />
            <InboxTodoItem
              v-for="todo in todos?.nodes" v-else :key="todo.link" :todo="todo"
            />
          </template>
          <template #mentions>
            <InboxZero v-if="mentions?.totalCount === 0" />
            <InboxMentionItem
              v-for="mention in mentions?.nodes"
              v-else
              :id="mention.id"
              :key="mention.id"
              :title="mention.title"
              :link="mention.link"
              :author="mention.author"
              :text="mention.text"
              :created-date="new Date(mention.createdOn)"
              :seen-date="mention.seenOn ? new Date(mention.seenOn) : undefined"
              @click="$event => markMentionAsRead({ id: $event })"
            />
          </template>
          <template v-if="activeTab === 'mentions' && unreadMentions" #extraHeader>
            <UButton
              size="sm"
              variant="link"
              class="mr-2"
              icon="light:check"
              data-action="inbox-mark-all-as-read"
              @click="markAllAsRead"
            >
              Mark all as read
            </UButton>
          </template>
        </WTabs>
      </div>
    </template>
  </UPopover>
</template>
