<script setup lang="ts">
import { useStorage } from '@vueuse/core'

const { workspaceId } = defineProps<{ workspaceId: string }>()
const route = useRoute()

const { workspace } = useWorkspaceData(toRef(() => workspaceId))
const { topNavControls } = useAppElementRefs()
const showNav = useStorage('showNav', true)

function getBreadcrumbItemFromRoute() {
  switch (route.name) {
    case 'teamspace-workspaceId-reviews':
      return 'Reviews'
    case 'teamspace-workspaceId-surveys':
      return 'Surveys'
    case 'teamspace-workspaceId-dashboards':
      return 'Dashboards'
    case 'teamspace-workspaceId':
      return 'Home'
    default:
      return ''
  }
}

const breadcrumbLinks = computed(() => [{
  label: workspace.value?.name ?? '',
  to: { name: 'teamspace-workspaceId' as const, params: { workspaceId } },
}, {
  label: getBreadcrumbItemFromRoute(),
}])
</script>

<template>
  <div class="mx-auto flex h-14 w-full max-w-7xl items-center !px-12 pt-6 transition-all" :class="{ '!px-16': !showNav }">
    <UBreadcrumb v-if="workspace" :links="breadcrumbLinks" divider="/">
      <template #divider>
        <span class="text-gray-500">/</span>
      </template>
    </UBreadcrumb>

    <div ref="topNavControls" class="ml-auto" />
  </div>
</template>
