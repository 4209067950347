export function getIconFromDashboardName(dashboardName: string) {
  const nameToIconMapping: Record<string, string> = {
    'Investment Mix': 'light:circle-dollar-to-slot',
    'DORA Metrics': 'light:chart-line',
    'KTLO': 'light:heart-pulse',
    'Delivery': 'light:code-pull-request',
  }

  if (dashboardName in nameToIconMapping) {
    return nameToIconMapping[dashboardName]
  }
  else {
    const allIcons = Object.values(nameToIconMapping)
    return allIcons[Math.floor(Math.random() * allIcons.length)]
  }
}
