<script setup lang="ts">
import type { WorkspaceNavigationItem } from './Workspace.vue'
import { promiseTimeout } from '@vueuse/core'
import ConfirmDialog from '../ConfirmDialog.vue'
import { DeleteWorkspaceDocument, FavoriteWorkspaceDocument, UnfavoriteWorkspaceDocument } from './Workspace.generated'

const props = defineProps<{
  workspaces: WorkspaceNavigationItem[]
  favoriteWorkspaces: boolean
  isLastWorkspace: boolean
}>()

const { canChangeOrgConfig } = useCurrentUser()
const { enabled: areDashboardsEnabled } = useFlag('DASHBOARDS')
const openDashboards = ref(false)

const router = useRouter()
const route = useRoute()
const currentWorkspaceId = computed(() => (route.params as { workspaceId: string }).workspaceId)

function getSubLinks(wId: string) {
  return [
    // { label: 'Dashboards', icon: 'solid:chart-user', click: () => openDashboards.value = !openDashboards.value },
    { label: 'Reviews', to: `/teamspace/${wId}/reviews`, icon: 'solid:file' },
    { label: 'Surveys', to: `/teamspace/${wId}/surveys`, icon: 'solid:clipboard-check' },
  ]
  // .filter(({ label }) => label === 'Dashboards' ? areDashboardsEnabled.value : true)
}

const { execute: deleteWorkspace } = useMutation(DeleteWorkspaceDocument, { refetchTags: ['workspaces'] })
const { execute: favoriteWorkspace } = useMutation(FavoriteWorkspaceDocument, { refetchTags: ['workspaces'] })
const { execute: unfavoriteWorkspace } = useMutation(UnfavoriteWorkspaceDocument, { refetchTags: ['workspaces'] })

const confirmDialog = ref<InstanceType<typeof ConfirmDialog>>()
const toBeDeletedWorkspaceName = ref('')

async function maybeDeleteWorkspace(workspace: { id: string, label: string }) {
  toBeDeletedWorkspaceName.value = workspace.label
  const { isCanceled } = await confirmDialog.value!.reveal()
  if (isCanceled)
    return

  const response = await deleteWorkspace({ id: workspace.id })
  const error = handleMutationError({ combinedError: response.error, errors: response.data?.deleteWorkspace?.errors })
  if (!error) {
    localStorage.removeItem('last-workspace')
    await navigateTo({ name: 'index' })
  }
}

function items(workspace: { id: string, label: string }) {
  return [
    [
      {
        label: props.favoriteWorkspaces ? 'Remove from favorites' : 'Add to favorites',
        icon: 'light:star',
        click: () => props.favoriteWorkspaces ? unfavoriteWorkspace({ id: workspace.id }) : favoriteWorkspace({ id: workspace.id }),
      },
      {
        label: 'Teamspace settings',
        icon: 'light:gear',
        click: () => navigateTo({
          name: 'settings-teamspace-teamspaceId',
          params: { teamspaceId: workspace.id },
        }),
      },
    ],
    [{
      label: 'Delete',
      icon: 'light:trash-can',
      disabled: props.isLastWorkspace, // prevent deleting last workspace
      click: () => { maybeDeleteWorkspace(workspace) },
    }],
  ]
}

function handleAccordionClick(workspaceId: string) {
  if (areDashboardsEnabled.value) {
    navigateTo({ name: 'teamspace-workspaceId', params: { workspaceId } })
  }
  else {
    const [reviewsLink] = getSubLinks(workspaceId)
    if (reviewsLink.to)
      router.push(reviewsLink.to)
  }
}

function expanded(workspaceId: string) {
  return currentWorkspaceId.value === workspaceId
}

async function scrollToWorkspace() {
  const workspace = await waitForElement(`[data-active-teamspace="true"]`)
  if (workspace) {
    await promiseTimeout(100)
    workspace.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

function onWorkspaceHome(workspaceId: string) {
  return route.name === 'teamspace-workspaceId' && expanded(workspaceId)
}

function onDashboardPage(workspaceId: string) {
  return route.name === 'teamspace-workspaceId-dashboards-dashboardId' && expanded(workspaceId)
}

onMounted(() => {
  scrollToWorkspace()
})
</script>

<template>
  <ConfirmDialog ref="confirmDialog" is-delete>
    <div>Are you sure you want to delete '{{ toBeDeletedWorkspaceName }}' teamspace?</div>
  </ConfirmDialog>

  <div class="flex w-full flex-col gap-y-2">
    <div v-for="workspace in workspaces" :key="workspace.id" class="flex flex-col px-2">
      <UButton
        color="white-nav"
        variant="ghost"
        active-class="dark:bg-gray-800 bg-gray-200"
        class="group/navItem relative mb-px flex flex-1 justify-between px-2"
        :class="onWorkspaceHome(workspace.id) ? ['bg-blue-80', 'dark:bg-gray-900'] : []"
        :data-active-teamspace="expanded(workspace.id)"
        @click="handleAccordionClick(workspace.id)"
      >
        <div v-if="onWorkspaceHome(workspace.id)" class="absolute inset-y-1.5 left-[-5px] w-[3px] rounded-md bg-blue-600" />
        <UTooltip
          :text="workspace.label"
          :prevent="workspace.label.length < 23"
          :popper="{ placement: 'top', offsetDistance: 14 }"
          :open-delay="500"
          class="flex flex-1 items-center gap-x-2 truncate"
        >
          <WorkspaceIcon v-if="workspace.marker" :marker="workspace.marker" class="shrink-0 grow-0" />
          <span class="truncate text-left text-nav font-light text-gray-900 dark:text-white">
            {{ workspace.label }}
          </span>
          <UIcon
            name="solid:caret-right"
            class="shrink-0 grow-0 text-sm text-gray-600 transition-transform duration-200 dark:text-gray-400"
            :class="{ 'rotate-90': expanded(workspace.id) }"
          />
        </UTooltip>
        <OptionsDropdown
          v-if="canChangeOrgConfig"
          :popper="{ offsetDistance: 12, offsetSkid: 6 }"
          :items="items(workspace)"
          :ui="{ width: 'w-48' }"
          name="org"
          @click.stop.prevent
        >
          <OptionsButton
            size="xs"
            color="nav"
            class="invisible group-hover/navItem:visible"
            data-testid="org-dropdown-button"
          />
        </OptionsDropdown>
      </UButton>

      <TransitionHeight :visible="expanded(workspace.id)">
        <UPopover
          v-if="areDashboardsEnabled && workspace.dashboards.length > 0"
          v-model:open="openDashboards"
          :popper="{ placement: 'bottom-start', offsetDistance: -36, offsetSkid: 212 }"
          class="pl-4"
        >
          <UButton
            color="white-nav"
            size="md"
            variant="ghost"
            class="group relative flex w-full items-center justify-start px-2 font-light"
            :class="onDashboardPage(workspace.id) || openDashboards ? ['bg-blue-80', 'dark:bg-gray-900', 'dark:text-white'] : []"
          >
            <UIcon
              name="solid:chart-user"
              class="!size-4 shrink-0 grow-0 group-hover:text-gray-700 dark:group-hover:text-gray-200"
              :class="onDashboardPage(workspace.id) || openDashboards ? ['text-gray-700', 'dark:text-gray-200'] : ['text-gray-400', 'dark:text-gray-500']"
            />
            <div v-if="onDashboardPage(workspace.id)" class="absolute inset-y-1.5 left-[-5px] w-[3px] rounded-md bg-blue-600" />
            <div class="-ml-0.5 text-nav">
              Dashboards
            </div>
            <UIcon
              name="solid:caret-right"
              class="ml-auto shrink-0 grow-0 text-sm text-gray-600 transition-transform duration-200 dark:text-gray-400"
              :class="{ 'rotate-180': openDashboards }"
            />
          </UButton>
          <template #panel="{ close }">
            <div class="flex flex-col gap-px bg-blue-50 p-1 text-gray-900 dark:bg-gray-950 dark:text-gray-400  ">
              <NuxtLink
                v-for="dashboard in workspace.dashboards"
                :key="dashboard.id"
                :to="{
                  name: 'teamspace-workspaceId-dashboards-dashboardId',
                  params: { workspaceId: workspace.id, dashboardId: dashboard.id },
                }"
                class="rounded-md p-2 opacity-75 hover:bg-blue-80 hover:opacity-100 dark:hover:bg-gray-900 dark:hover:text-white"
                @click="close"
              >
                <div class="flex items-center gap-x-2">
                  <UIcon :name="getIconFromDashboardName(dashboard.name)" />
                  <div>{{ dashboard.name }}</div>
                </div>
              </NuxtLink>
            </div>
          </template>
        </UPopover>
        <UVerticalNavigation
          :links="getSubLinks(workspace.id)"
          class="pl-4"
          :ui="{ padding: 'p-2', size: '!text-nav', font: 'font-light', icon: { base: 'flex-shrink-0 size-4 relative' } }"
        >
          <template #default="{ link, isActive }">
            <div v-if="isActive" class="absolute inset-y-1.5 left-[-5px] w-[3px] rounded-md bg-blue-600" />
            <span class="relative truncate">{{ link.label }}</span>
          </template>
        </UVerticalNavigation>
      </TransitionHeight>
    </div>
  </div>
</template>
