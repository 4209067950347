<script setup lang="ts">
import { useStorage } from '@vueuse/core'
import { useRouteParams } from '@vueuse/router'

const { mainElement } = useAppElementRefs()

const workspaceId = useRouteParams<string>('workspaceId')
const showNav = useStorage('showNav', true)

const route = useRoute()
const hideBreadcrumbs = computed(() => Boolean(route.meta.hideBreadcrumbs))
</script>

<template>
  <NuxtLoadingIndicator />
  <ImpersonationWarning />
  <div class="flex min-h-0 flex-1 dark:bg-gray-900 print:block">
    <div
      class="flex max-w-[240px] flex-1 border-r border-gray-200 transition-all dark:border-gray-800"
      :class="{ 'ml-[-240px]': !showNav }"
    >
      <Navigation />
    </div>

    <div class="flex min-h-0 flex-1 flex-col overflow-auto">
      <slot v-if="!hideBreadcrumbs" name="topNav">
        <div class="flex items-center">
          <slot name="topNavContent">
            <TopNav v-if="workspaceId" :workspace-id="workspaceId" />
          </slot>
        </div>
      </slot>

      <main
        ref="mainElement"
        class="flex flex-1 flex-col dark:bg-gray-900 print:h-auto"
      >
        <slot />
      </main>
    </div>
  </div>
</template>
